<template>
  <el-dialog
    :title="`${dataForm.id ? '修改' : '新增'}指派任务`"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <div class="tablePart">
        <el-form-item label="智能取餐柜" prop="freezerId">
          <el-select v-model="dataForm.freezerId" filterable>
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="上货柜" prop="upperShelves">
          <el-input v-model="dataForm.upperShelves" placeholder="上货柜" />
        </el-form-item>
        <el-form-item label="中货柜" prop="middleShelves">
          <el-input v-model="dataForm.middleShelves" placeholder="中货柜" />
        </el-form-item>
        <el-form-item label="下货柜" prop="lowerShelves">
          <el-input v-model="dataForm.lowerShelves" placeholder="下货柜" />
        </el-form-item>
        <el-form-item label="配送员" prop="deliveryId">
          <el-select v-model="dataForm.deliveryId" filterable>
            <el-option
              v-for="(item, index) in deliveryList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
import { getFreezerList, getDeliveryList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visiblePopover: false,
      roleList: [],
      dataForm: {
        id: '',
        deliveryId: '',
        freezerId: '',
        upperShelves: '',
        middleShelves: '',
        lowerShelves: '',
      },
      deliveryList: [],
      freezerList: [],
      dataRule: {
        deliveryId: [
          {
            required: true,
            message: '配送员不能为空',
            trigger: 'blur',
          },
        ],
        freezerId: [
          {
            required: true,
            message: '智能取餐柜不能为空',
            trigger: 'blur',
          },
        ],
        upperShelves: [
          {
            required: true,
            message: '上货柜不能为空',
            trigger: 'blur',
          },
        ],
        middleShelves: [
          {
            required: true,
            message: '中货柜不能为空',
            trigger: 'blur',
          },
        ],
        lowerShelves: [
          {
            required: true,
            message: '下货柜不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id) {
      this.getDeliveryList();
      this.getFreezerList();
      this.$init({
        url: id ? `/delivery/deliverytask/info/${id}` : null,
        after: (data) => {
          if (data && data.code === 0) {
            this.dataForm = pick(data.deliveryTask, keys(this.dataForm));
          }
        },
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$submit({
        url: `/delivery/deliverytask/${!this.dataForm.id ? 'save' : 'update'}`,
      });
    },
    getDeliveryList() {
      getDeliveryList().then(({ data }) => {
        if (data && data.code === 0) {
          this.deliveryList = data.list;
        }
      });
    },
    getFreezerList() {
      getFreezerList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        }
      });
    },
  },
};
</script>
<style scoped>
.mod-org {
  .org-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  & __icon-popover {
    max-width: 350px;
  }

  & __icon-list {
    max-height: 380px;
    padding: 0;
    margin: -8px 0 0 -8px;

    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;

      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
